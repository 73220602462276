<template>
    <div id="page-user-list">
      <div class="vx-card p-6">
        <div class="flex flex-wrap items-center">
  
          <!-- ITEMS PER PAGE -->
          <div class="flex-grow">
          
            <br>
            <vx-card>
              <div class="vx-card__title">
              <h4>ผู้ชนะตลอดกาล (ยอดได้มากที่สุด)</h4>
              <br>
            </div>

              <div class="export-table">
                <vs-table stripe pagination max-items="10" :data="winTable" search>
  
                  <template slot="thead">
                    <vs-th> อันดับ</vs-th>
                    <vs-th> ยูเซอร์เนม</vs-th>
                    <vs-th> ยอดได้เสีย </vs-th>
  
                  </template>
  
                  <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td > {{data[indextr].rank}}</vs-td>
                      <vs-td class="text-danger"><router-link :to="`/member/${data[indextr].member_username}`">{{ data[indextr].member_username}}</router-link></vs-td>
                      <vs-td>{{ data[indextr].member_tour_winlose }}</vs-td>
  
                    </vs-tr>
                  </template>
  
                </vs-table>
              </div>
            </vx-card>
            <br>
            <vx-card>
              <div class="vx-card__title">
              <h4>ผู้ไม่ย่อท้อต่อทุกปัญหา (ยอดเสียมากที่สุด)</h4>
              <br>
            </div>
              <div class="export-table">
                <vs-table stripe pagination max-items="10"   :data="loseTable" search>
  
                  <template slot="thead">
                    <vs-th> อันดับ</vs-th>
                    <vs-th> ยูเซอร์เนม</vs-th>
                    <vs-th> ยอดได้เสีย </vs-th>
  
                  </template>
  
                  <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                      <vs-td > {{data[indextr].rank}}</vs-td>
                      <vs-td class="text-danger"><router-link :to="`/member/${data[indextr].member_username}`">{{ data[indextr].member_username}}</router-link></vs-td>
                      <vs-td>{{ data[indextr].member_tour_winlose }}</vs-td>
                    </vs-tr>
                  </template>
  
                </vs-table>
              </div>
            </vx-card>
  
            <vx-card>
              <div class="vx-card__title">
              <h4> นักพนันตัวยง (ทำเทิร์นมากที่สุด)</h4>
            </div>
  
              <div class="export-table">
                <vs-table stripe pagination max-items="10"   :data="turnTable" search>
  
                  <template slot="thead">
                    <vs-th> อันดับ</vs-th>
                    <vs-th> ยูเซอร์เนม</vs-th>
                    <vs-th> ยอดเทิร์น </vs-th>
  
                  </template>
  
                  <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                        <vs-td > {{data[indextr].rank}}</vs-td>
                      <vs-td class="text-danger"><router-link :to="`/member/${data[indextr].member_username}`">{{ data[indextr].member_username}}</router-link></vs-td>
                      <vs-td>{{ data[indextr].member_tour_turnover }}</vs-td>
                    </vs-tr>
                  </template>
  
                </vs-table>
              </div>
            </vx-card>

            <vx-card>
              <div class="vx-card__title">
              <h4> รายที่ผู้เข้าร่วมรายการ</h4>
            </div>
  
              <div class="export-table">
                <vs-table stripe pagination max-items="10"   :data="registerTable" search>
  
                  <template slot="thead">
                    <vs-th> จำนวน</vs-th>
                    <vs-th> ยูเซอร์เนม</vs-th>
  
                  </template>
  
                  <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                        <vs-td > {{data[indextr].rank}}</vs-td>
                        <vs-td class="text-danger"><router-link :to="`/member/${data[indextr].member_username}`">{{ data[indextr].member_username}}</router-link></vs-td>
                    </vs-tr>
                  </template>
  
                </vs-table>
              </div>
            </vx-card>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  
<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import times from './ReportAff'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import axios from '../../axios'
import moment from 'moment'
  
// function currencyFormatter (params) {
//   return (params.value).replace(/\d(?=(\d{3})+\.)/g, '$&,')
// }
export default {
  components: {
    vSelect,
    flatPickr,
    times,
    StatisticsCardLine
  },
  data () {
    return {
      winTable:[],
      loseTable:[],
      turnTable:[],
      registerTable:[],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      member_register_date: moment.tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm'),
      memberData: [],
      sum_data: []
    }
  },
  async mounted () {

    await axios
      .get('reporttour/win')
      .then(response => (this.winTable = response.data))

    await axios
      .get('reporttour/lose')
      .then(response => (this.loseTable = response.data))

    await axios
      .get('reporttour/turn')
      .then(response => (this.turnTable = response.data))

    await axios
      .get('reporttour/register')
      .then(response => (this.registerTable = response.data))
  },
  methods: {
    clearFields2 () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    gotomember (username) {
      this.$router.push(`member/${username}`)
    }
  
  }
  
}
  
</script>
  